<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ $tc('salesOrder.title', 2) }}</h1>
    <resource
        ref="resource"
        :can-delete="false"
        :can-search="true"
        :use-checkboxes="false"
        :can-add="false"
        :can-update="false"
        :show-speed-dial="false"
        :index-request="index"
        :show-request="show"
        :params="params"
        :update-request="mapAndUpdate"
        :form-component="() => import('@/components/forms/SalesOrderForm.vue')"
        :meta="{name: $tc('salesOrder.title', 1), namePlural: $tc('salesOrder.title', 2)}"
        :table-content="tableContent"
    >
      <template #afterSearchBar>
        <v-row no-gutters class="justify-end">
          <v-col  cols="5">
            <SalesOrderStatusAutocomplete solo v-model="params.salesOrderStatusIds" multiple chips :return-object="false"/>
          </v-col>
        </v-row>
      </template>
      <template #crudActionsAfter="{resource}">
        <v-tooltip left>
          <template #activator="{on, attrs}">
            <v-btn text :color="!resource.hasMetaData ? 'grey' : ''" v-bind="attrs" v-on="on" @click="handleEditClick(resource)">A</v-btn>
          </template>
          {{ resource.hasMetaData ? $t('salesOrder.editTooltipWithMetaData') : $t('salesOrder.editTooltipWithoutMetaData') }}
        </v-tooltip>
      </template>
    </resource>
  </v-container>
</template>

<script lang="js">
import Resource from '@/components/vuetifyResource/Resource.vue';
import eventBus from '@/eventBus';
import { index, show, update} from '@/api/endpoints/salesOrder.js';
import SalesOrderStatusAutocomplete from "@/components/autocompletes/SalesOrderStatusAutocomplete.vue";
import { snakeToCamel } from "@/api/util/keyConverter.js";
import Qs from "qs";

export default {
  name: 'SalesOrderTable',
  components: {
    Resource,
    SalesOrderStatusAutocomplete,
  },
  data: () => ({
    params: {},
  }),
  computed: {
    tableContent() {
      return [
        {
          text: this.$t('salesOrder.table.orderNumber'),
          value: 'orderNumber',
        },
        {
          text: this.$t("salesOrder.table.clientName"),
          value: "clientName",
          sortable: false,
        },
        {
          text: this.$t('salesOrder.table.companyName'),
          value: 'companyName',
        },
        {
          text: this.$t('salesOrder.table.orderDate'),
          value: 'orderDate',
        },
        {
          text: this.$t('salesOrder.table.deliveryDate'),
          value: 'deliveryDate',
        },
        {
          text: this.$t('salesOrder.table.statusName'),
          value: 'statusName',
        },
        {
          text: this.$t('salesOrder.table.assignedUserName'),
          value: 'assignedUserName',
        },
      ];
    },
  },
  created() {
    this.setBreadCrumbs();
    const params = this.getQueryParams();
    if (params.salesOrderStatusIds) {
      params.salesOrderStatusIds = params.salesOrderStatusIds.map((id) => Number(id));
    }
    this.params = params;
  },
  methods: {
    index,
    show,
    mapAndUpdate(resource) {
      return update({
        id: resource.id,
        assignedUserId: resource.assignedUser?.id,
        salesOrderIssueId: resource.salesOrderIssue?.id,
        statusId: resource.status?.id,
        comment: resource.comment,
      })
    },
    handleEditClick(item) {
      this.$refs.resource.$refs.resourceList.openUpdateHandler(item.salesOrderId)
    },
    getQueryParams() {
      return snakeToCamel(Qs.parse(this.$route.query, { allowSparse: false }));
    },
    setBreadCrumbs() {
      eventBus.$emit('setBreadcrumbs', [
        {
          exact: true,
          to: { name: 'home' },
          text: this.$t('global.dashboard'),
        },
        {
          exact: true,
          to: { name:  'salesOrder' },
          text: this.$tc('salesOrder.title', 2),
        },
      ]);
    }
  }
};
</script>
